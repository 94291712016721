<template>
  <div class="">
    <div class="row">
      <div class="col-12 col-md-5">
        <documents-send-mail-form
          :documentData="purchasesOrder"
          :dispatchUrl="'purchasesOrders/sendMail'"
          :toggleMailModal="toggleMailModal"
          :mailInformation="mailInformation"
        />
      </div>
      <div class="col-12 col-md-7" style="max-height: 100%">
        <purchases-order-view-details
          :showHeader="false"
          :purchasesOrder="purchasesOrder"
          @purchasesOrderItemsUpdated="get"
        />
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <el-button type="danger" @click="toggleMailModal">
        {{ $t("COMMON.CANCEL") }}
      </el-button>
    </div>
  </div>
</template>
<script>
import {formatNumber, parseNumber} from 'libphonenumber-js';
import { Button } from "element-ui";
import "flatpickr/dist/flatpickr.css";
import formMixin from "@/mixins/form-mixin";
import DocumentsSendMailForm from "@/components/DocumentsSendMailForm";
import PurchasesOrderViewDetails from "./PurchasesOrderViewDetails";

export default {
  layout: "DashboardLayout",
  name: "sales-order-mail-modal-content",

  components: {
    DocumentsSendMailForm,
    PurchasesOrderViewDetails,
    [Button.name]: Button,
  },

  mixins: [formMixin],
  props: {
    purchasesOrder: {
      default: () => {},
    },
    toggleMailModal: {
      type: Function,
    },
    get: {
      type: Function,
    },
    loading: {
      default: () => true,
    },
  },

  data() {
    return {
      submitting: false,
      mailInformation: {
        organization: {},
        recipient: {},
        user: {},
      },
    };
  },

  created() {},

  mounted() {
    this.refreshMailInformation();
  },

  methods: {
    formatPhoneNumber(phoneNumber) {
      try {
        return formatNumber(parseNumber(phoneNumber), "NATIONAL");
      } catch (e) {
        return phoneNumber;
      }
    },

    refreshMailInformation() {
      this.user = this.$store.getters["profile/me"];

      this.mailInformation = {
        organization: this.purchasesOrder.organization,
        subject: this.$t("COMMON.EMAIL_INFOS_SUBJECT_PURCHASES_ORDER", {
          client_id: this.purchasesOrder.issuer
            ? `#${this.purchasesOrder.issuer.customer_number}`
            : "",
          establisment_name: this.purchasesOrder.establishment
            ? this.purchasesOrder.establishment.company_name
            : this.purchasesOrder.organization.name,
        }),
        recipient: {
          username: this.purchasesOrder?.issuer?.customer_name,
          email: this.purchasesOrder?.issuer?.email,
        },
        body: this.$t("COMMON.PURCHASES_ORDER_EMAIL_INFOS_BODY", {
          supplier_name: this.purchasesOrder?.issuer?.company_name,
          sender_name: `${this.user.firstname} ${this.user.lastname}`,
          phone: `${
            this.user.phone_type
              ? this.$t(`COMMON.PHONE_TYPE_${this.user.phone_type}`) + ":"
              : ""
          } ${this.formatPhoneNumber('+14504463088')}${
            this.user.phone_extension
              ? ` (ext: ${this.user.phone_extension})`
              : ""
          }`,
          email: this.user.email,
          organization: this.purchasesOrder.organization.name,
        }),
      };
    },
  },

  watch: {
    purchasesOrder(purchasesOrder) {
      this.refreshMailInformation();
    },
  },
};
</script>
