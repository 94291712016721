<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="" @submit.prevent="">
      <div>
        <base-input
          v-if="
            purchasesOrderItemModel.purchasesOrderable.type.includes('products')
          "
          :label="`${$t(`COMMON.PRODUCT`)} (*)`"
        >
          <product-selector
            :filterOrganization="purchasesOrder.organization.id"
            :productObject="purchasesOrderItemModel.purchasesOrderable"
            :allowNone="false"
            :showAll="false"
            :filterWarehouse="
              purchasesOrder.destinationWarehouse
                ? purchasesOrder.destinationWarehouse.id
                : null
            "
            :filterSupplier="
              purchasesOrder.issuer ? purchasesOrder.issuer.id : null
            "
            :filterCanBePurchased="true"
            @productChanged="productChanged"
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.purchasesOrderable" />
      </div>

      <base-input
        :label="`${$t('COMMON.CODE')} (*)`"
        :placeholder="$t('COMMON.CODE')"
        v-model="purchasesOrderItemModel.code"
        input-classes="form-control-alternative"
        v-if="!purchasesOrderItemModel.purchasesOrderable.type"
      >
      </base-input>
      <validation-error
        v-if="!purchasesOrderItemModel.purchasesOrderable.type"
        :errors="apiValidationErrors.code"
      />

      <base-input
        :label="$t('COMMON.EXCERPT')"
        :placeholder="$t('COMMON.EXCERPT')"
        input-classes="form-control-alternative"
      >
        <html-editor
          v-model="purchasesOrderItemModel.excerpt"
          @change="onFormChanged()"
        >
        </html-editor>
      </base-input>
      <validation-error :errors="apiValidationErrors.excerpt" />

      <div class="row px-3">
        <div class="col-4">
          <base-input
            :label="`${$t('COMMON.QUANTITY')} (*)`"
            :placeholder="$t('COMMON.QUANTITY')"
            v-model="purchasesOrderItemModel.quantity"
            type="number"
            step="1"
            input-classes="form-control-alternative"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.quantity" />
        </div>
        <div class="col-4">
          <base-input
            :label="$t('COMMON.UNIT_OF_MEASURE_UNIT')"
            :placeholder="$t('COMMON.UNIT_OF_MEASURE_UNIT')"
            input-classes="form-control-alternative"
          >
            <unit-of-measure-unit-selector
              :filterOrganization="purchasesOrder.organization.id"
              :filterUnitOfMeasure="unitOfMeasure?.id"
              :unit="purchasesOrderItemModel.unitOfMeasureUnit?.id"
              :allowNone="false"
              :showAll="false"
              :disabled="true"
              @unitChanged="
                (unit) => {
                  unitOfMeasureUnitChanged(unit);
                }
              "
            />
          </base-input>
          <validation-error :errors="apiValidationErrors.taxGroups" />
        </div>
        <div class="col-4">
          <base-input
            :label="`${$t('COMMON.UNIT_PRICE')} (*)`"
            :placeholder="$t('COMMON.UNIT_PRICE')"
            :disabled="true"
            v-model="purchasesOrderItemModel.unit_price"
            type="number"
            step="0.0001"
            input-classes="form-control-alternative"
          >
          </base-input>
          <validation-error :errors="apiValidationErrors.unit_price" />
        </div>
      </div>

      <template v-if="!purchasesOrder.has_no_taxes">
        <base-input
          :label="$t('COMMON.TAX_GROUPS')"
          :placeholder="$t('COMMON.TAX_GROUPS')"
          input-classes="form-control-alternative"
        >
          <tax-groups-selector
            :taxGroups="purchasesOrderItemModel.taxGroups"
            :disabled="true"
            @taxGroupsChanged="
              (taxGroups) => {
                purchasesOrderItemModel.taxGroups = taxGroups;
              }
            "
          />
        </base-input>
        <validation-error :errors="apiValidationErrors.taxGroups" />
      </template>
    </form>

    <template slot="footer">
      <button
        v-if="!purchasesOrderItemModel.id"
        type="submit"
        class="btn btn-primary"
        @click="submitPurchasesOrderItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.ADD_PURCHASES_ORDER_ITEM") }}
      </button>

      <base-button
        v-if="purchasesOrderItemModel.id"
        native-type="submit"
        type="primary"
        class="new-purchasesOrder-item--add"
        @click="submitPurchasesOrderItemForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.UPDATE_PURCHASES_ORDER_ITEM") }}
      </base-button>

      <base-button
        v-if="purchasesOrderItemModel.id"
        type="danger"
        @click="deletePurchasesOrderItem(purchasesOrderItemModel.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("PURCHASES_ORDERS.DELETE_PURCHASES_ORDER_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closePurchasesOrderItemModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Tooltip,
} from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import TaxGroupsSelector from "@/components/TaxGroupsSelector";
import ProductSelector from "@/components/ProductSelector.vue";
import ValidationError from "@/components/ValidationError.vue";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
import UnitOfMeasureUnitSelector from "@/components/UnitOfMeasureUnitSelector.vue";
import defaultPurchasesOrderItem from "../defaultPurchasesOrderItem";

export default {
  name: "purchasesOrder-view-details-purchasesOrder-item-form",

  components: {
    HtmlEditor,
    ValidationError,
    ProductSelector,
    TaxGroupsSelector,
    UnitOfMeasureUnitSelector,
    [Tooltip.name]: Tooltip,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: [
    "purchasesOrder",
    "purchasesOrderItem",
    "showModal",
    "loading",
    "formErrors",
  ],

  data() {
    return {
      purchasesOrderItemModel: cloneDeep(defaultPurchasesOrderItem),
      unitOfMeasure: null,
    };
  },

  computed: {},

  methods: {
    closePurchasesOrderItemModal() {
      this.$emit("onClosePurchasesOrderItemModal", true);
    },

    submitPurchasesOrderItemForm() {
      const purchasesOrderItemData = cloneDeep(this.purchasesOrderItemModel);
      purchasesOrderItemData.unit_price = parseFloat(
        purchasesOrderItemData.unit_price
      );
      purchasesOrderItemData.quantity = parseInt(
        purchasesOrderItemData.quantity,
        10
      );
      purchasesOrderItemData.discount = parseFloat(
        purchasesOrderItemData.discount
      );
      if (!purchasesOrderItemData.purchasesOrderable.id) {
        purchasesOrderItemData.purchasesOrderable = null;
      }

      this.$emit("onSubmitPurchasesOrderItemForm", purchasesOrderItemData);
    },

    deletePurchasesOrderItem(purchasesOrderItemId) {
      this.$emit("onDeletePurchasesOrderItem", purchasesOrderItemId);
    },

    async unitOfMeasureUnitChanged(unit) {
      const productPricing = await this.$getProductUnitOfMeasureUnitPrice(
        this.purchasesOrderItemModel.purchasesOrderable,
        unit.id
      );
      this.purchasesOrderItemModel.unitOfMeasureUnit = unit;
      this.purchasesOrderItemModel.unit_price = productPricing.buying_price;
    },

    productChanged(productId, product) {
      this.purchasesOrderItemModel.purchasesOrderable = product;
      this.purchasesOrderItemModel.excerpt = product.denomination;

      if (
        product.orderUnitOfMeasureUnit &&
        product.orderUnitOfMeasureUnitPrice
      ) {
        this.purchasesOrderItemModel.unit_price =
          product.orderUnitOfMeasureUnitPrice;
        this.purchasesOrderItemModel.code = product.sku || product.code;
      } else if (product.warehouseProduct) {
        this.purchasesOrderItemModel.unit_price =
          product.warehouseProduct.buying_price;
        this.purchasesOrderItemModel.code =
          product.warehouseProduct.sku || product.sku || product.code;
      } else if (product.supplierProduct) {
        this.purchasesOrderItemModel.unit_price =
          product.supplierProduct.buying_price;
        this.purchasesOrderItemModel.code =
          product.supplierProduct.sku || product.sku || product.code;
      } else {
        this.purchasesOrderItemModel.unit_price = product.buying_price;
        this.purchasesOrderItemModel.code = product.code;
      }

      if (product.orderUnitOfMeasureUnit) {
        this.purchasesOrderItemModel.unitOfMeasureUnit =
          product.orderUnitOfMeasureUnit;
      }
      if (!this.purchasesOrder.has_no_taxes) {
        this.purchasesOrderItemModel.taxGroups = product.taxGroups;
      } else {
        this.purchasesOrderItemModel.taxGroups = [];
      }
      this.unitOfMeasure = product.unitOfMeasure;
    },
  },

  mounted() {},

  watch: {
    purchasesOrderItem(purchasesOrderItem) {
      if (purchasesOrderItem) {
        this.purchasesOrderItemModel = cloneDeep(purchasesOrderItem);
        this.unitOfMeasure = purchasesOrderItem.unitOfMeasureUnit.unitOfMeasure;
      }
      this.resetApiValidation();
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
