<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">
          {{ $t("COMMON.PURCHASES_INVOICES") }}
        </span>
      </div>
    </div>

    <purchases-invoice-list-table :filterPurchasesOrder="purchasesOrder.id" />
  </div>
</template>

<script>
import formMixin from "@/mixins/form-mixin";
import PurchasesInvoiceListTable from "../../PurchasesInvoiceManagement/partials/PurchasesInvoiceListTable.vue";

export default {
  name: "purchases-order-view-invoices",

  components: {
    PurchasesInvoiceListTable,
  },

  mixins: [formMixin],

  props: ["purchasesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
<style scoped>
.invoice-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
